:global(#signUpButton) {
  position: relative;
  z-index: 1;
  & a {
    border-radius: 45px;
    border: 1px solid var(--web-h-3, #538abe);
    background: var(--black-shade-white, #fff);
    box-shadow: 0px 35px 90px 0px rgba(0, 0, 0, 0.14);
    color: var(--web-primary, #3d70a1);
    font-family: Nunito;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 302px;
    height: 67px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: unset;
    @media (max-width: 767px) {
      font-size: 14px;
      width: 70%;
      height: 48px;
    }
  }
}
/* Landing page  */

:global(#hero_banner) {
  justify-content: flex-start;
  position: relative;
  z-index: 1;
  height: 655px;
  @media (max-width: 767px) {
    height: 600px;
  }
  & > div {
    &:nth-child(2) {
      max-width: 100%;
      margin: 0 auto;
      width: 100%;
      height: 100%;
      padding: 120px 0;
      background: #01061278;
      @media (max-width: 767px) {
        padding: 100px 0;
      }
      & > div {
        display: flex;
        max-width: 1530px;
        margin: 0 auto;
        width: 100%;
        padding-top: 30px;
        @media (max-width: 767px) {
          flex-direction: column;
          padding: 24px 24px 0;
        }
      }
    }
  }
  & header {
  }
  & h1 {
    font-family: Montserrat;
    font-size: 48px;
    font-weight: 700;
    line-height: 58.51px;
    text-align: center;
    text-wrap: pretty;
    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 34px;
    }
  }
  & p {
    color: #fff;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.94px;
    text-align: center;
    max-width: 650px;
    padding: 0;
    margin: 0;
    text-wrap: wrap;
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
  & a {
    text-align: left;
    justify-self: start;
  }
}

:global(#our_service_section) {
  & > div {
    padding: 80px 0 0;
    @media (max-width: 767px) {
      padding: 48px 0 0;
    }
    & > div {
      max-width: 100%;
      display: flex;
      gap: 0;
      padding: 0;
      background-color: #3d70a1;
      border-radius: 45px;
      @media (max-width: 767px) {
        flex-direction: column;
      }
    }
  }
  & header {
    max-width: calc(1466px + 64px);
    justify-content: start;
    & h1 {
      font-family: Montserrat;
      font-size: 36px;
      font-weight: 700;
      line-height: 43.88px;
      text-align: left;
      justify-self: left;
      color: #272727;
      @media (max-width: 767px) {
        font-size: 30px;
        line-height: 40px;
      }
    }
    & p {
      font-family: Nunito;
      font-size: 28px;
      font-weight: 500;
      line-height: 38.19px;
      text-align: left;
      padding: 0;
      margin: 18px 0 63px;
      color: #949494;
      @media (max-width: 767px) {
        font-size: 20px;
        line-height: 30px;
        margin: 10px 0 30px;
      }
    }
  }
  & h2 {
    font-family: Montserrat;
    font-size: 22px;
    font-weight: 800;
    line-height: 26.82px;
    text-align: left;
    width: 299px;
    height: 67px;
    border-radius: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 61px;
    @media (max-width: 767px) {
      font-size: 20px;
      height: 55px;
      width: 230px;
      margin: 0 0 41px;
    }
  }
  & h3 {
    font-family: Nunito;
    font-size: 28px;
    font-weight: 500;
    line-height: 38.19px;
    text-align: left;
    padding: 0;
    margin: 0;
    @media (min-width: 1300px) {
      padding-left: 30px;
    }
    @media (max-width: 1023px) {
      font-size: 22px;
      line-height: 34px;
    }
  }
  & ul {
    margin: 0 0 20px;
    text-indent: 8px;
    max-width: 552px;
    @media (min-width: 1300px) {
      margin-left: 18px;
    }
    @media (max-width: 1023px) {
      margin: 0 0 16px;
    }
  }
  & li {
    font-family: Nunito;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.55px;
    text-align: left;
    @media (max-width: 1023px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
  & :global(#our_service_section-block-1) {
    padding: 44px 90px 20px 143px;
    background: #ffffff;
    border-radius: 45px;
    width: 50%;
    @media (max-width: 1500px) {
      padding: 44px 60px 20px 80px;
    }
    @media (max-width: 1023px) {
      padding: 36px;
    }
    @media (max-width: 767px) {
      width: 100%;
    }
    & h2 {
      background: #3d70a1;
      color: #fff;
    }
    & h3,
    & li {
      color: #272727;
    }
  }
  & :global(#our_service_section-block-2) {
    padding: 44px 77px 20px 60px;
    width: 50%;
    @media (max-width: 1023px) {
      padding: 36px;
    }
    @media (max-width: 767px) {
      width: 100%;
    }
    & h2 {
      background: #ffffff;
      color: #3d70a1;
    }
    & h3,
    & li {
      color: #ffffff;
    }
    & ul {
      max-width: 590px;
    }
  }
}

:global(#why_choose_us) {
  padding-top: 80px;
  & :nth-child(1) {
    & img {
      object-fit: contain;
      width: 100%;
      height: auto;
    }
  }
  & :global(#why_choose_us-block-1) {
    & > div:nth-child(2) {
      /* border-right: 1px solid #d6d6d6; */
      position: relative;
      padding-right: 24px;
      @media (max-width: 767px) {
        border-right: none;
      }
      &:after {
        position: absolute;
        content: '';
        background: #d6d6d6;
        width: 1px;
        height: 100%;
        top: 0;
        bottom: 0;
        right: -10px;
        @media (max-width: 767px) {
          content: unset;
        }
      }
    }
  }
  & :global(#why_choose_us-block-2) {
    & > div:nth-child(2) {
      /* border-right: 1px solid #d6d6d6; */
      padding-right: 24px;
      position: relative;
      @media (max-width: 767px) {
        border-right: none;
      }
      &:after {
        position: absolute;
        content: '';
        background: #d6d6d6;
        width: 1px;
        height: 100%;
        top: 0;
        bottom: 0;
        right: -10px;
        @media (max-width: 767px) {
          content: unset;
        }
      }
    }
  }
  & :global(#why_choose_us-block-3) {
    & > div:nth-child(2) {
      /* border-right: 1px solid #d6d6d6; */
      padding-right: 30px;
      position: relative;
      @media (max-width: 767px) {
        border-right: none;
      }
      &:after {
        position: absolute;
        content: '';
        background: #d6d6d6;
        width: 1px;
        height: 100%;
        top: 0;
        bottom: 0;
        right: -10px;
        @media (max-width: 767px) {
          content: unset;
        }
      }
    }
  }
  & header {
    & h2 {
      color: #272727;
      font-family: Montserrat;
      font-size: 36px;
      font-weight: 700;
      line-height: 43.88px;
      text-align: center;
      padding: 0;
      @media (max-width: 767px) {
        font-size: 32px;
        justify-self: center;
      }
    }
    p {
      color: #949494;
      font-family: Nunito;
      font-size: 28px;
      font-weight: 500;
      line-height: 38.19px;
      text-align: center;

      margin: 14px 0 61px;
      padding: 0;
      @media (max-width: 767px) {
        font-size: 22px;
        line-height: 32px;
        margin: 10px 0 30px;
      }
    }
  }
  & > div {
    padding: 0px 0;
    & > img {
      object-position: 0 -218px;
      @media (max-width: 767px) {
        object-position: 0;
      }
    }
    & > div {
      max-width: 1680px;
      gap: 51px;
      padding: 30px 99px 59px 107px;
      display: flex;
      justify-content: space-evenly;
      /* flex-wrap: wrap; */
      border-radius: 15px;
      background: var(--black-shade-white, #fff);
      box-shadow: 0px 10px 35px 0px rgba(227, 227, 227, 0.12);
      @media (max-width: 1500px) {
        gap: 41px;
        padding: 30px 79px 59px 87px;
      }
      @media (max-width: 1400px) {
        flex-wrap: wrap;
      }
      @media (max-width: 767px) {
        padding: 30px 24px;
        justify-content: center;
      }
      & img {
        width: 100%;
        height: 100%;
        max-height: 108px;
        max-width: 108px;
        object-fit: contain;
      }
      & h3 {
        color: var(--black-shade-text-black, #272727);
        font-family: Nunito;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0;
        padding: 0;
        @media (max-width: 767px) {
          font-size: 18px;
        }
      }
      & p:nth-child(2) {
        color: #949494;
        font-family: Nunito;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 6px 0 0px;
        padding: 0;
        @media (max-width: 767px) {
          font-size: 16px;
        }
      }
      & > div {
        align-items: flex-start;
        flex-direction: column;
        gap: 2px;
        /* justify-content: space-between; */
        max-width: 324px;
        @media (max-width: 1023px) {
          max-width: 280px;
        }
        @media (max-width: 767px) {
          max-width: 330px;
        }
        & > div {
          &:nth-child(1) {
            & > div {
              & > div {
                padding-bottom: 0 !important;
                & > div {
                  position: unset;
                }
              }
            }
          }
          &:nth-child(2) {
            margin: 0;
          }
        }
      }
    }
  }
}
:global(#your_career) {
  & > div {
    padding: 60px 0 70px;
    @media (min-width: 768px) {
      padding: 123px 0 163px;
    }
    & > div {
      max-width: calc(1224px + 64px);
      display: flex;
      gap: 100px;
      @media (max-width: 1500px) {
        gap: 60px;
      }
      @media (max-width: 1023px) {
        gap: 30px;
      }
      @media (max-width: 767px) {
        flex-direction: column;
        align-items: center;
        gap: 0;
      }
    }
  }
  & h3 {
    font-family: Montserrat;
    font-size: 48px;
    font-weight: 500;
    line-height: 58.51px;
    text-align: left;
    color: #272727;
    padding: 0;
    @media (max-width: 1200px) {
      font-size: 42px;
      line-height: 52px;
    }
    @media (max-width: 1023px) {
      font-size: 30px;
      line-height: 42px;
    }
  }
  & p {
    font-family: Nunito;
    font-size: 28px;
    font-weight: 500;
    line-height: 38.19px;
    text-align: justified;
    margin: 33px 0 44px;
    color: #272727;
    padding: 0;
    @media (max-width: 1200px) {
      font-size: 24px;
      line-height: 34px;
      margin: 23px 0 30px;
    }
    @media (max-width: 1023px) {
      font-size: 20px;
      line-height: 30px;
      margin: 16px 0 24px;
    }
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 28px;
    }
  }
  & :global(#your_career-block-1) {
    width: 25%;
    min-width: 260px;
  }
  & :global(#your_career-block-2) {
    width: 75%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    @media (max-width: 767px) {
      width: 100%;
    }
    & > div {
      &:nth-child(1) {
        max-width: 469px;
        gap: 20px;
        display: flex;
        width: 100%;
        @media (max-width: 1023px) {
          max-width: 360px;
        }
        @media (max-width: 580px) {
          max-width: 290px;
          gap: 16px;
        }
        & img {
          width: 100%;
          height: 100%;
        }
        & > div {
          width: 50%;
        }
      }
    }
  }
}
/* testimonials_section */
:global(#testimonials_section) {
  background-color: #f8f8f8;
  & header {
    justify-content: center;
    & h2 {
      color: #272727;
      font-family: Montserrat;
      font-size: 36px;
      font-weight: 700;
      line-height: 43.88px;
      text-align: center;
      justify-self: center;
      padding: 0;
      margin: 0 0 14px;
      @media (max-width: 767px) {
        font-size: 32px;
      }
    }
    p {
      color: var(--black-shade-para-text, #949494);
      font-family: Nunito;
      font-size: 28px;
      font-weight: 500;
      line-height: 38.19px;
      text-align: center;

      margin: 0;
      padding: 0;
      @media (max-width: 767px) {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
  & > div {
    padding: 0px 0 78px;
    background-color: #f8f8f8;
    @media (max-width: 767px) {
      padding: 0px 0 38px;
    }
  }
  & :global(#testimonials_section-container) {
    padding: 0;
    & buttton {
      width: 36px;
      height: 36px;
      font-size: 48px;
      margin-top: 25px;
    }
    & > div {
      & button:nth-child(1) {
        left: 14px;
        @media (max-width: 767px) {
          padding: 0px 3px 5px 4px;
          font-size: 43px;
          width: 40px;
          height: 40px;
        }
      }
      & button:nth-child(2) {
        right: 16px;
        @media (max-width: 767px) {
          padding: 0px 3px 5px 4px;
          font-size: 43px;
          width: 40px;
          height: 40px;
        }
      }
    }
  }
  & :global(#testimonials_section-slider) {
    padding-top: 86px;
    padding-bottom: 0;
    @media (max-width: 767px) {
      padding-top: 76px;
    }
    & > div {
      display: flex;
      border-radius: 15px;
      background: var(--black-shade-white, #fff);
      box-shadow: 10px 10px 15px 0px rgba(0, 0, 0, 0.03);
      position: relative;
      padding: 47px 17px 27px;
      margin-right: 22px;
      text-align: center;
      &:before {
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg width='70' height='54' viewBox='0 0 70 54' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M33.1476 0L15.5989 39.2903L13.649 27.4839C17.1588 27.4839 20.0836 28.7097 22.4234 31.1613C24.8932 33.4839 26.1281 36.3871 26.1281 39.871C26.1281 44 24.7632 47.4194 22.0334 50.129C19.3036 52.7097 16.1188 54 12.4791 54C8.96936 54 5.97957 52.8387 3.50975 50.5161C1.16992 48.0645 0 45.0968 0 41.6129C0 39.5484 0.324976 37.6129 0.974929 35.8065C1.75487 33.871 2.92479 31.6129 4.48468 29.0323L20.8635 0H33.1476ZM70 0L52.4513 39.2903L50.5014 27.4839C54.0111 27.4839 56.9359 28.7097 59.2758 31.1613C61.7456 33.4839 62.9805 36.3871 62.9805 39.871C62.9805 44 61.6156 47.4194 58.8858 50.129C56.156 52.7097 52.9712 54 49.3315 54C45.8217 54 42.8319 52.8387 40.3621 50.5161C38.0223 48.0645 36.8524 45.0968 36.8524 41.6129C36.8524 39.5484 37.1773 37.6129 37.8273 35.8065C38.6072 33.871 39.7772 31.6129 41.337 29.0323L57.7159 0H70Z' fill='%23F0FBFF'/%3E%3C/svg%3E%0A");
        top: 24px;
        left: 24px;
        width: 70px;
        height: 54px;
      }
      & > div {
        margin: 0;
        &:nth-child(1) {
          position: absolute;
          left: 117px;
          top: -53px;
          width: 110px;
          height: 110px;
          & > div {
            & > div {
              & > div {
              }
            }
          }
        }
      }
    }
    & h3 {
      color: var(--web-primary, #3d70a1);
      font-family: Nunito;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 0;
      @media (max-width: 767px) {
        font-size: 16px;
      }
    }
    & p {
      &:nth-child(2) {
        position: relative;
        height: 19px;
        padding: 0;
        margin: 0;
        color: #fff;
        &:after {
          position: absolute;
          content: '';
          background-image: url("data:image/svg+xml,%3Csvg width='118' height='19' viewBox='0 0 118 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.0991 0L12.3665 6.40423H19.7039L13.7678 10.3623L16.0352 16.7665L10.0991 12.8085L4.163 16.7665L6.43039 10.3623L0.494285 6.40423H7.83171L10.0991 0Z' fill='%23E28415'/%3E%3Cpath d='M34.5495 0L36.8169 6.40423H44.1544L38.2183 10.3623L40.4856 16.7665L34.5495 12.8085L28.6134 16.7665L30.8808 10.3623L24.9447 6.40423H32.2821L34.5495 0Z' fill='%23E28415'/%3E%3Cpath d='M59 0L61.2674 6.40423H68.6048L62.6687 10.3623L64.9361 16.7665L59 12.8085L53.0639 16.7665L55.3313 10.3623L49.3952 6.40423H56.7326L59 0Z' fill='%23E28415'/%3E%3Cpath d='M83.4504 0L85.7178 6.40423H93.0552L87.1191 10.3623L89.3865 16.7665L83.4504 12.8085L77.5143 16.7665L79.7817 10.3623L73.8456 6.40423H81.183L83.4504 0Z' fill='%23E28415'/%3E%3Cpath d='M107.901 0L110.168 6.40423H117.506L111.57 10.3623L113.837 16.7665L107.901 12.8085L101.965 16.7665L104.232 10.3623L98.296 6.40423H105.633L107.901 0Z' fill='%23E28415'/%3E%3C/svg%3E%0A");
          top: 0px;
          left: 0px;
          right: 0;
          margin: 0 auto;
          width: 118px;
          height: 19px;
        }
      }
      &:nth-child(3) {
        color: var(--black-shade-para-text, #949494);
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 0;
        margin: 7px 0 0;
      }
    }
  }
}
/* section-8 */
:global(#faq_section) {
  background: var(--Black-Shade-BG, #f8f8f8);
  & header {
    & h2 {
      font-family: Montserrat;
      font-size: 36px;
      font-weight: 700;
      line-height: 43.88px;
      text-align: center;
      color: #272727;
      padding: 0;
      margin: 0 0 14px;
      @media (max-width: 767px) {
        font-size: 32px;
      }
    }
    p {
      color: var(--black-shade-para-text, #949494);
      font-family: Nunito;
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0;
      padding: 0;
      text-align: center;
      @media (max-width: 767px) {
        font-size: 22px;
      }
    }
  }
  & > div {
    padding: 42px 0 120px;
    @media (max-width: 767px) {
      padding: 42px 0 82px;
    }
    & > div {
      display: flex;
      justify-content: space-between;
      border-radius: 15px;
      background: #fff;
      box-shadow: 0px 6.25px 20px 0px rgba(226, 226, 226, 0.26);
      margin-top: 48px;
      padding: 11px 60px 0;
      max-width: 1465px;
      @media (max-width: 1200px) {
        padding: 11px 40px 0;
      }
      @media (max-width: 767px) {
        flex-direction: column;
        padding: 11px 20px 0;
      }
      & > div {
        align-items: flex-end;
        &:nth-child(1) {
          width: 45%;
          @media (max-width: 767px) {
            width: 100%;
          }
        }
        &:nth-child(2) {
          width: 55%;
          max-width: 681px;
          @media (max-width: 767px) {
            width: 100%;
          }
        }
      }
    }
  }
}
