.faqContent {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 0px;
  row-gap: 0px;
  max-width: 560px;
  padding-top: 21px;
  padding-bottom: 20px;
  @media (max-width: 1023px) {
    max-width: 100%;
  }
  & .faqText {
    position: relative;
    /* &:last-child{
           & svg{
              top: 34px;
              @media (max-width: 767px) {
                top: 28px;
              }
            }
          } */
    & svg {
      position: absolute;
      top: 23px;
      right: 21px;
      @media (max-width: 767px) {
        top: 28px;
        right: 15px;
      }
    }
  }
  & :global(.is-open) {
    position: relative;
    &:after {
      position: absolute;
      content: '';
      height: 24px;
      width: 24px;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 12H19' stroke='%233D70A1' stroke-width='2' strokeLinecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      right: 12px;
    }
  }
  & :global(.is-closed) {
    position: relative;
    &:after {
      position: absolute;
      content: '';
      height: 24px;
      width: 24px;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 5V19' stroke='%23949494' stroke-width='2' strokeLinecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5 12H19' stroke='%23949494' stroke-width='2' strokeLinecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      right: 12px;
    }
  }
  & :global(.Collapsible__trigger) {
    /* composes: h1 from global; */
    display: block;
    margin-bottom: 0px;
    padding: 10px 64px 0px 18px;
    color: var(--black-shade-text-black, #272727);
    font-family: Nunito;
    font-size: 24px;
    font-weight: 400;
    line-height: 42px;
    text-align: left;

    height: auto;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    @media (max-width: 1300px) {
      padding: 15px 48px 0px 18px;
    }

    @media (max-width: 767px) {
      padding: 15px 44px 0px 18px;
      height: 44px;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
    }
    &:before {
      position: absolute;
      content: '';
      height: 7px;
      width: 7px;
      background: #272727;
      border-radius: 100px;
      left: 0px;
    }
  }
  & :global(.Collapsible) {
    height: 100%;
  }
  & :global(.Collapsible__contentInner) {
    margin: 0 15px;
    padding-bottom: 16px;
    color: var(--black-shade-para-text, #949494);
    font-family: Nunito;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.55px;
    text-align: left;

    max-width: 423px;
    border-bottom: 1px solid #dfdfdf;
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
.faqWrapper {
  & h3 {
    font-family: Nunito;
    font-size: 28px;
    font-weight: 500;
    line-height: 38.19px;
    text-align: left;
    color: #e28415;
    margin: 48px 0 16px;
    padding: 0;
    @media (max-width: 767px) {
      font-size: 22px;
      line-height: 32px;
      margin: 16px 0 16px;
    }
  }
  & h2 {
    color: #272727;
    font-family: Montserrat;
    font-size: 36px;
    font-weight: 500;
    line-height: 43.88px;
    text-align: left;
    padding: 0;
    margin: 0 0 80px;
    @media (max-width: 1200px) {
      font-size: 30px;
      line-height: 40px;
      margin: 0 0 16px;
    }
    @media (max-width: 767px) {
      font-size: 26px;
      line-height: 38px;
      margin: 0 0 16px;
    }
  }
}
