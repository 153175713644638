:global(#hero_banner_after_login) {
  justify-content: flex-start;
  position: relative;
  z-index: 1;
  height: 650px;
  @media (max-width: 767px) {
    height: 620px;
  }
  & img {
    object-position: top;
  }
  & > div {
    &:nth-child(1) {
      left: 16px;
      right: 16px;
    }
    &:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 100%;
      padding-top: 175px;
    }
  }
  & header {
    max-width: calc(1472px + 64px);
    width: 100%;
    justify-content: start;
  }
  & h1 {
    text-align: left;
    justify-self: start;
    color: #000;
    font-family: Montserrat;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 711px;
    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 34px;
    }
  }
  & p {
    text-align: left;
    justify-self: start;
    color: #000;
    font-family: Nunito;
    font-size: 24px;
    font-weight: 500;
    line-height: 32.74px;
    text-align: left;
    max-width: 650px;
    margin: 16px 0 50px;
    padding: 0;
    text-wrap: wrap;
    max-width: 800px;
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 28px;
      margin: 10px 0 36px;
    }
  }
  & a {
    text-align: left;
    justify-self: start;
    background: #538abe;
    box-shadow: 0px 4px 40px 0px #0000001a;
    border-radius: 45px;
    font-family: Nunito;
    font-size: 24px;
    font-weight: 700;
    line-height: 32.74px;
    text-align: left;
    color: #fff;
    height: 62px;
    width: 202px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1023px) {
      font-size: 20px;
      line-height: 30px;
      height: 52px;
      width: 190px;
    }
  }
}
:global(#quote_section) {
  background-color: #fff;
  & > div {
    padding: 260px 0 143px;
    @media (max-width: 1200px) {
      padding: 200px 0 63px;
    }
    @media (max-width: 1023px) {
      padding: 150px 0 63px;
    }
    @media (max-width: 600px) {
      padding: 110px 0 63px;
    }

    & > div {
      max-width: calc(1405px + 64px);
    }
  }
  & h3 {
    font-family: Montserrat;
    font-size: 36px;
    font-weight: 400;
    line-height: 43.88px;
    text-align: center;
    color: #000;
    margin: 0 0 33px;
    padding: 0;
    @media (max-width: 1023px) {
      font-size: 32px;
      line-height: 42px;
      margin: 0 0 20px;
    }
    @media (max-width: 600px) {
      font-size: 22px;
      line-height: 34px;
      margin: 0 0 20px;
    }
  }
  & h6 {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 700;
    line-height: 24.38px;
    text-align: center;
    color: #000;
    padding: 0;
    margin: 0 0 10px;
    text-transform: capitalize;
    @media (max-width: 1023px) {
      font-size: 18px;
      line-height: 28px;
    }
  }
  & p {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.94px;
    text-align: center;
    color: #000;
    padding: 0;
    margin: 0;
    max-width: 100%;
    @media (max-width: 1023px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
  & :global(#quote_section-block-1) {
    position: absolute;
    left: 70px;
    top: 60px;
    width: 300px;
    height: 200px;
    @media (max-width: 1200px) {
      width: 200px;
      height: 120px;
    }
    @media (max-width: 1023px) {
      width: 150px;
      height: 100px;
      left: 30px;
      top: 40px;
    }
    @media (max-width: 1023px) {
      width: 100px;
      height: 60px;
      left: 20px;
    }
  }
  & :global(#quote_section-block-3) {
    position: absolute;
    right: 100px;
    bottom: 32px;
    width: 300px;
    height: 200px;
    @media (max-width: 1200px) {
      width: 200px;
      height: 120px;
    }
    @media (max-width: 1023px) {
      width: 150px;
      height: 100px;
      right: 40px;
      bottom: 24px;
    }
    @media (max-width: 1023px) {
      width: 100px;
      height: 60px;
      right: 20px;
      bottom: 0px;
    }
  }
}
:global(#connect_with_qualified) {
  height: 907px;
  background-color: #fff;
  @media (max-width: 767px) {
    height: 780px;
  }
  & img {
    object-position: right;
  }
  & > div {
    &:nth-child(1) {
      left: 16px;
      right: 16px;
    }
    &:nth-child(2) {
      padding-top: 314px;
      @media (max-width: 767px) {
        padding-top: 200px;
      }
    }
    & > div {
      max-width: calc(1416px + 64px);
      & > div {
        max-width: 859px;
      }
    }
  }
  & h3 {
    font-family: Montserrat;
    font-size: 48px;
    font-weight: 700;
    line-height: 58.51px;
    text-align: left;
    color: #fff;
    padding: 0;
    margin: 0 0 10px;
    @media (max-width: 767px) {
      font-size: 30px;
      line-height: 40px;
    }
  }
  & p {
    padding: 0;
    margin: 0 0 89px;
    font-family: Nunito;
    font-size: 24px;
    font-weight: 500;
    line-height: 32.74px;
    text-align: left;
    color: #fff;
    @media (max-width: 1023px) {
      margin: 0 0 30px;
      font-size: 20px;
      line-height: 30px;
    }
    @media (max-width: 767px) {
      margin: 0 0 20px;
      font-size: 18px;
      line-height: 28px;
    }
  }
  & a {
    background: #538abe;
    box-shadow: 0px 4px 40px 0px #0000001a;
    border-radius: 45px;
    font-family: Nunito;
    font-size: 24px;
    font-weight: 700;
    line-height: 32.74px;
    text-align: left;
    color: #fff;
    height: 62px;
    width: 202px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1023px) {
      font-size: 20px;
      line-height: 30px;
      height: 52px;
      width: 190px;
    }
  }
}
:global(#trusted_by) {
  background-color: #fff;
  & > div {
    padding: 31px 0 0px;
    & > div {
      padding-top: 0;
      max-width: calc(1380px + 64px);
      gap: 35px;
      @media (min-width: 768px) {
        grid-template-columns: repeat(3, calc((100% - 2 * 35px) / 3));
      }
      @media (max-width: 767px) {
        gap: 0;
      }
      & > div {
        padding: 77px 24px 76px;
        @media (max-width: 1023px) {
          padding: 40px 24px;
        }
      }
    }
  }
  & h3 {
    font-family: Montserrat;
    font-size: 64px;
    font-weight: 400;
    line-height: 78.02px;
    text-align: center;
    color: #3d70a1;
    padding: 0;
    margin: 0 0 40px;
    @media (max-width: 1200px) {
      font-size: 40px;
      line-height: 50px;
      margin: 0 0 20px;
    }
  }
  & p {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;
    line-height: 29.26px;
    text-align: center;
    color: #000;
    padding: 0;
    margin: 0px;
    @media (max-width: 1200px) {
      font-size: 18px;
      line-height: 28px;
    }
  }
  & header {
    max-width: calc(1150px + 64px);
    & h2 {
      font-family: Montserrat;
      font-size: 36px;
      font-weight: 700;
      line-height: 43.88px;
      text-align: left;
      color: #000;
      padding: 0;
      margin: 0 0 30px;
      @media (max-width: 767px) {
        font-size: 26px;
        line-height: 34px;
        margin: 0 0 20px;
        justify-self: center;
      }
    }
    & p {
      font-family: Montserrat;
      font-size: 20px;
      font-weight: 500;
      line-height: 35px;
      text-align: center;
      color: #000;
      padding: 0;
      margin: 0 0 30px;
      text-wrap: pretty;
      max-width: 100%;
      @media (max-width: 767px) {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}
:global(#doctors_section) {
  & > div {
    padding: 80px 0 90px;
    @media (max-width: 1023px) {
      padding: 40px 0 40px;
    }
    & > div {
      max-width: calc(1380px + 64px);
      gap: 90px;
      grid-template-columns: repeat(3, calc((100% - 2 * 90px) / 3));
      @media (max-width: 1300px) {
        gap: 45px;
      }
      @media (max-width: 1023px) {
        grid-template-columns: repeat(2, calc((100% - 1 * 45px) / 2));
        gap: 32px;
      }
      @media (max-width: 767px) {
        gap: 24px;
        grid-template-columns: repeat(1, calc((100% - 0 * 45px) / 1));
      }
      & > div {
        box-shadow: 0px 0px 40px 4px #0000000a;
        padding: 35px 50px 30px;
        & > div {
          &:nth-child(1) {
            width: 90px;
            height: 90px;
            margin: 0px auto 37px;
          }
        }
      }
    }
  }
  & h3 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
    color: #000;
    padding: 0;
    margin: 0 0 14px;
  }
  & p {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    color: #949494;
    padding: 0;
    margin: 0px;
    @media (max-width: 1200px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
  & header {
    max-width: calc(995px + 64px);
    & h2 {
      font-family: Montserrat;
      font-size: 48px;
      font-weight: 700;
      line-height: 58.51px;
      text-align: center;
      color: #3d70a1;
      padding: 0;
      margin: 0 0 20px;
      justify-self: center;
      @media (max-width: 1023px) {
        font-size: 40px;
        line-height: 50px;
      }
      @media (max-width: 767px) {
        font-size: 30px;
        line-height: 40px;
      }
    }
    & p {
      font-family: Montserrat;
      font-size: 20px;
      font-weight: 500;
      line-height: 24.38px;
      text-align: center;
      color: #000;
      padding: 0;
      margin: 0;
      max-width: 100%;
      text-wrap: pretty;
      @media (max-width: 767px) {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}
:global(#about_us_after_login) {
  height: 838px;
  background-color: #fff;
  & img {
    object-position: right;
  }
  & > div {
    &:nth-child(1) {
      left: 16px;
      right: 16px;
    }
    &:nth-child(2) {
      padding-top: 234px;
    }
    & > div {
      max-width: calc(1416px + 64px);
    }
  }
  & h3 {
    font-family: Montserrat;
    font-size: 36px;
    font-weight: 700;
    line-height: 43.88px;
    text-align: left;
    color: #fff;
    padding: 0;
    margin: 0 0 40px;
    @media (max-width: 1023px) {
      font-size: 30px;
      line-height: 40px;
      margin: 0 0 20px;
    }
  }
  & p {
    padding: 0;
    margin: 0 0 40px;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 400;
    line-height: 35px;
    text-align: left;
    color: #fff;
    max-width: 780px;
    @media (max-width: 767px) {
      font-size: 15px;
      line-height: 20px;
      margin: 0 0 20px;
    }
    &:nth-child(3) {
      margin-bottom: 0;
    }
  }
}
:global(#faq_section_after_login) {
  background: #fff;
  & header {
    & h2 {
      color: var(--web-h-1, #e28415);
      font-family: Montserrat;
      font-size: 36px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 0;
      margin: 0 0 14px;
      @media (max-width: 767px) {
        font-size: 32px;
      }
    }
    p {
      color: var(--black-shade-para-text, #949494);
      font-family: Nunito;
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0;
      padding: 0;
      @media (max-width: 767px) {
        font-size: 22px;
      }
    }
  }
  & > div {
    padding: 90px 0 80px;
    @media (max-width: 1200px) {
      padding: 60px 0;
    }
    @media (max-width: 767px) {
      padding: 0px 0 50px;
    }
    & > div {
      display: flex;
      border-radius: 15px;
      background: #fff;
      padding: 0 60px 0;
      max-width: calc(1448px + 120px);
      @media (max-width: 1200px) {
        padding: 0 40px 0;
      }
      @media (max-width: 1023px) {
        flex-direction: column;
        padding: 0 20px 0;
        gap: 12px;
      }
      & > div {
        align-items: flex-end;
        &:nth-child(1) {
          width: 50%;
          @media (max-width: 1023px) {
            width: 100%;
          }
        }
        &:nth-child(2) {
          width: 50%;
          @media (max-width: 1023px) {
            width: 100%;
            max-width: 600px;
            margin: 0 auto;
          }
        }
      }
    }
  }
}
