@import '../../../../styles/customMediaQueries.css';

.root {
  border-top: 1px solid var(--colorGrey100);
  background: var(--Web-Primary, #3d70a1) !important;
}

.footer {
  max-width: 1524px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 18px 32px 29px;
  border-bottom: 1px solid #2967a4;
}

.footer ul {
  list-style-type: disc;
  padding-inline-start: 0px;
  margin-top: 1px;
  & li {
    &::marker {
      margin-right: 6px;
      color: #fff;
      font-family: Nunito;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px; /* 177.778% */
      @media (max-width: 767px) {
        font-size: 16px;
      }
    }
    & a {
      color: #fff !important;
      font-family: Nunito;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px; /* 177.778% */
      @media (max-width: 767px) {
        font-size: 16px;
      }
    }
  }
}

.linkList {
  list-style-type: none;
  padding-left: 12px;
}

.content {
  position: relative;
  display: grid;
  justify-content: center;
  align-content: end;
  grid-auto-rows: auto;
  grid-gap: 32px;
  grid-row-gap: 16px;
  grid-template-columns: 1fr;
  grid-template-areas:
    'logo'
    'details'
    'grid'
    'links';
}

.grid {
  grid-area: grid;
  display: grid;
  justify-content: center;
  align-content: start;
  grid-auto-rows: auto;
  grid-gap: 32px;
  padding-top: 54px;
  @media (max-width: 1023px) {
    padding-top: 0px;
  }
}

.gridCol1,
.gridCol2,
.gridCol3,
.gridCol4 {
  grid-template-columns: 1fr;
}

.detailsInfo {
  grid-area: links;
  color: #fff;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 274px;
  @media (max-width: 767px) {
    font-size: 16px;
  }
}

.slogan {
  font-size: 16px;
  line-height: 24px;
}
.sloganMobile {
  grid-area: details;
  padding-bottom: 24px;
  & span {
    color: #fff;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
  @media (--viewportLarge) {
    display: none;
  }
}
.sloganDesktop {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.logo {
  grid-area: logo;
}

.copyright {
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  color: var(--colorGrey500);
  margin-top: 24px;
}

.icons {
  margin-top: 16px;
  @media (min-width: 1260px) {
    position: absolute;
    right: 100px;
    top: 210px;
  }
  @media (min-width: 1400px) {
    right: 133px;
    top: 200px;
  }
  @media (min-width: 1500px) {
    right: 150px;
    top: 200px;
  }
  & > div {
    &:nth-child(1) {
      & > a {
        & svg {
          right: 9px;
        }
      }
    }
    & > a {
      margin-right: 8px;
      background: #538abe;
      height: 30px;
      width: 30px;
      border-radius: 100px;
      position: relative;
      & svg {
        position: absolute;
        top: 8px;
        right: 7px;
        fill: #fff;
      }
    }
  }
}

.icon {
  font-size: 28px;
  margin: 15px 15px 0px 0px;
  padding: 10px 15px;
}

.logoLink {
  display: inline-block;
  padding: 0;

  &:hover {
    text-decoration: none;
  }
}
.logoWrapper {
  max-width: 370px;
}
.logoImage {
  height: auto;
  width: auto;
  max-width: 100%;
  object-fit: contain;
  object-position: center;

  @media (--viewportLarge) {
    max-width: min(100%, 298px);
  }
}

@media (min-width: 350px) {
  .gridCol2,
  .gridCol3,
  .gridCol4 {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 600px) {
  .gridCol3,
  .gridCol4 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (--viewportMedium) {
  .gridCol4 {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (--viewportLarge) {
  .detailsInfo {
    grid-area: details;
  }

  .contentCol1 {
    /* 1/6 of space is for grid, and grid uses 1 column internally */
    grid-template-columns: 2fr 3fr 1fr;
    grid-template-areas:
      'logo . .'
      'details . grid'
      'details . grid'
      'details . grid';
  }
  .contentCol2 {
    /* 2/6 of space is for grid, and grid splits it to 2 columns */
    grid-template-columns: 2fr 2fr 2fr;
    grid-template-areas:
      'logo . .'
      'details . grid'
      'details . grid'
      'details . grid';
  }
  .contentCol3 {
    /* 3/6 of space is for grid, and grid splits it to 3 columns */
    grid-template-columns: 2fr 1fr 1fr 2fr;
    grid-template-areas:
      'logo grid grid grid'
      'details grid grid grid'
      'details grid grid grid'
      'details grid grid grid';
  }
  .contentCol4 {
    /* 4/6 of space is for grid, and grid splits it to 4 columns */
    grid-template-columns: 2fr 2fr 2fr;
    grid-template-areas:
      'logo . .'
      'details grid grid'
      'details grid grid'
      'details grid grid';
  }

  .gridCol1 {
    grid-template-columns: 1fr;
  }

  .gridCol2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .gridCol3 {
    grid-template-columns: repeat(3, 1fr);
  }

  .gridCol4 {
    grid-template-columns: repeat(4, 1fr);
  }
}
