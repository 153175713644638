.root {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;

  &:first-child {
    margin-top: 0;
  }

  /* we style global elements only because they are generated by markdown processor */
  & a {
    color: var(--colorGrey700);

    &:hover {
      color: #ffffffac;
      text-decoration: none;
    }
  }

  & ol {
    margin-top: 0px;
    padding-left: 0px;
  }

  & li {
    font-size: 14px;
    color: var(--colorGrey700);
    padding: 0px;
    list-style-position: inside;
  }

  & p {
    padding: 0px;
  }
}
:global(#our_services) {
  & p {
    color: #fff;
    font-family: Nunito;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;
    @media (max-width: 767px) {
      font-size: 22px;
      margin-bottom: 16px;
    }
  }
}
:global(#quick_link) {
  & p {
    color: #fff;
    font-family: Nunito;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;
    @media (max-width: 767px) {
      font-size: 22px;
      margin-bottom: 16px;
    }
  }
}
:global(#support) {
  & > div {
    & :nth-child(1) {
      color: #fff;
      font-family: Nunito;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      @media (max-width: 767px) {
        font-size: 22px;
      }
    }
    & :nth-child(2) {
      color: #fff;
      font-family: Nunito;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      margin-top: 20px;
      max-width: 237px;
      @media (max-width: 767px) {
        margin-top: 16px;
      }
    }
  }
}
:global(#copy_right) {
  position: absolute;
  bottom: -63px;
  @media (max-width: 1023px) {
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    bottom: -78px;
  }
  & p {
    color: #fff;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
:global(#footer) {
  & > div {
    padding: 0 0 73px;
    @media (min-width: 768px) {
      padding-top: 0;
    }
  }
}
